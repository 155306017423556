
 body{
  background: radial-gradient(ellipse at left, #e8fff3, transparent),
            radial-gradient(ellipse at right, #fffacb, transparent);
}


.nav-bar {
	padding: 10px 56px;
  background: radial-gradient(ellipse at left, rgb(236, 255, 245), rgba(228, 255, 241, 0.4)),
                radial-gradient(ellipse at right, rgb(255, 251, 212), rgba(253, 248, 206, 0.4));
}

.nav-item {
	padding: 0px 15px;
	font-size: 1.5em;
}

.nav-bar .nav-item .nav-link {
	color: var(--secondary-color);
}

.nav-bar .nav-item .nav-link.active {
	color: #135200;
  font-weight: 500;
}


.language-switcher {
  display: flex;
  flex-direction: row;
}


.logo-img {
	width: 90px;
	height: 40px;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px;

}


.welcomepage {
  justify-content: space-between;
  min-height: 60vh;
  margin: 7% 5%;
}

.welcome-main {
  margin-top: 16%;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.wc-slogon{
  color: var(--polar-green-9, #135200);
  text-align: center;
  font-family: "Playfair Display", sans-serif;
  font-size: 50px;
  line-height: 80px;
  font-weight: 900;
}


.right-img {
  width: 284px;
  height: 544px;
  position: absolute;
  right: 0;
  top: 20%;
}
.left-img {
  width: 262px;
  height: 420px;
  position: absolute;
  top: 20%;
  left: 0;
}

.links-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 60px;
}

.button-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 346px;
  height: 67px;
  background: var(--polar-green-2, #d9f7be);
  border-radius: 20px;
  box-shadow: 0px 3px 3px 0px rgba(19, 82, 0, 0.2);
}

.welcome-icon{
  width: 35px;
  height: 35px;
}

.icon-text{
  color: var(--polar-green-10, #092b00);
  font-family: "Inter", sans-serif;
  font-size: 20px;
  line-height: 22px;
  font-weight: 400;
  margin-bottom: 0;
}

.button-links :hover {
  box-shadow: none !important;
  text-decoration: none;
}

.links-group :hover {
  box-shadow: 0px 4px 4px 0px rgba(19, 82, 0, 0.8);
  text-decoration: none;
}



/*----------------home page--------------------*/
.homepage {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 50vh;
  margin: 7% 5%;
}

.section1{
  display: flex;
  flex-direction:row;
  justify-content: space-between;
}

.laptop {
  width: 570px;
  height: 600px;
}

.project-slogon {
  color: var(--polar-green-9, #135200);
  font-family: "Playfair Display", sans-serif;
  font-size: 46px;
  line-height: 80px;
  font-weight: 900;
}

.project-description {
  color: var(--neutral-9, #434343);
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  position: relative;
  margin-top: 30px;
}

.learnIcon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.learn-more-button {
  box-sizing: border-box;
  background: var(--polar-green-9, #135200);
  border-radius: 10px;
  border-style: solid;
  border-color: var(--polar-green-8, #237804);
  border-width: 1px;
  padding: 4px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 153px;
  height: 43px;
  position: relative;
  box-shadow: var(
    --drop-shadow-button-primary-box-shadow,
    0px 2px 0px 0px rgba(0, 0, 0, 0.04)
  );
  margin-top: 40px;

}

.learn-more-button :hover {
  color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(19, 82, 0, 0.8);
}



.learn-more-text {
  color: var(--character-primaryinverse, #ffffff);
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  position: relative;
  text-decoration: none;
}

.button-text:hover {
  color: var(--character-primaryinverse, #ffffff);
}

.section2 {
  margin-top: 8%;
}

.section2-title {
  color: var(--polar-green-9, #135200);
  text-align: center;
  font-family: "Playfair Display", sans-serif;
  font-size: 30px;
  line-height: 40px;
  font-weight: 900;
}
.section2-quote {
  color: var(--neutral-7, #8c8c8c);
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
}

.section2-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 5%;
  margin-right: 5%;
}

.card {
  width: 20rem;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(19, 82, 0, 0.29);

}

.card-header {
  font-size: 18px;
  background: #3f6600;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
}

.cardicon {
  width: 50px;
  height: 50px;
}

.section3 {
  margin-top: 8%;
}

.section3-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
}

.section3-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.section3-title {
  color: var(--polar-green-9, #135200);
  text-align: left;
  font-family: "Playfair Display", sans-serif;
  font-size: 40px;
  line-height: 40px;
  font-weight: 900;
  margin-bottom: 30px;
}

.solution-title {
  color: #000000;
  text-align: left;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.solution-detail {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.solution-text {
  color: #000000;
  text-align: left;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 300;
}

.section3-button {
  display: flex;
  flex-direction: row;
  justify-content: left;
  gap: 200px;
}

.button-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
  text-align: center;
  background: var(--lime-9, #3f6600);
  border-radius: 20px;
  width: 523px;
  height: 92px;
  padding: 10px 20px;
}

.button-text :hover {
  text-decoration: none;
}

.button-icon {
  width: 65px;
  height: 65px;
}

.button-text {
  color: #ffffff;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  line-height: 22px;
  font-weight: 400;
}
.right-icon {
  width: 35px;
  height: 35px;
}


.button-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}


/*----------------chat page------------------*/
.chatpage {
  margin-top: 7%;
}

.center-div {
  width: 62vw;
  height: 80vh;
}

iframe {
  border: none;
}

.loading {
  display: flex;     
  justify-content: center;
  align-items: center;  
  position: fixed;    
  top: 0;               
  left: 0;              
  width: 100vw;       
  height: 100vh;   
  z-index: 1000; 
  font-size: 20pt;
  color: #135200;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}


.learn-page {
  min-height: 90vh;

}

.cardbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12%;
}

.FlashcardWrapper__item--front{
  outline: 0.5rem solid #B9C9AB;
  border-radius: 12px;
}

.FlashcardWrapper__item--back {
  outline: 0.5rem solid #B9C9AB;
  border-radius: 12px;
}

.flashcontent{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:20px;
}

.FlashcardArray {
  font-size: 80px;
}







.tapbox .overlap-group {
  display: flex;
  flex-direction: row;
  gap:10px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid;
  border-color: #237804;
  border-radius: 0px 20px 20px 20px;
  box-shadow: 0px 4px 4px #00000040;
  padding: 5px 20px;

}

.tapbox .tap {
  height: 50px;
  width: 50px;
  padding: 5px;
}

.tapbox .text-wrapper {
  color: #00000073;
  font-family: "Inter-Regular", Helvetica;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
}



.flash-nav {
  border-radius: 0px 5px 5px 0px;
  height: 40px;
  border: none;
  background-color:#237804;
  color: #ffffff;
}

/*----------------about page------------------*/
.aboutpage {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
  margin: 7% 5%;
}

.abt-section1 {
  display: flex;
  flex-direction:row;
  gap: 150px;
}

.abt-content {
  flex: 1;
}

.abt-icon {
	max-width: 570px;
	max-height: 600px;
}


.abt-title {
  color: var(--polar-green-9, #135200);
  font-family: "Playfair Display", sans-serif;
  font-size: 46px;
  line-height: 80px;
  font-weight: 900;
}

.abt-intro {
  color: var(--neutral-9, #135200);
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 40px;
  font-weight: 400;
  position: relative;
  margin-top: 30px;
}

.abt-section2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 50px;
}

.aboutcard {
  width: 15%;
  border-radius: 15px;
}

.card-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.card-name {
  color: #000000;
  font-family: "Playfair Display", sans-serif;
  font-size: 18px;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 30px;
  font-weight: bold;
}

.abt-section3 {
  margin-top: 100px;
}

.org-title {
  font-size: 18px;
  margin-top: 20px;
}

.org-link {
  color: #135200;
  text-decoration: none;
}

.org-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.section3-content-title {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.solution-title {
  margin-bottom: 0;
}

.linkedin-icon {
  width: 20x;
  height: 20px;
  margin-left: 5px;
}

.lastmyle-icon {
  width: 20x;
  height: 20px;
  margin-left: 5px;
}

.trophy {
  width: 100px;
  height: 100px;
}




@media (max-width: 767px) {
  .homepage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 85vh;
  }

  .laptop{
    display: none;
  }

  .wc-slogon {
    margin-top: 50px;
  }

  .links-group {
    flex-direction: column;
    gap:30px;
  }

  .footer {
    margin-top: auto;
  }

  .button-links {
    width: 100%;
    margin-bottom: 10px;
  }

  .section1{
    margin-top: 50px;
  }

  .section2-bottom{
    flex-direction: column;
  }

  .section3-bottom {
    flex-direction: column;
    gap: 50px;
  }

  .section3-button {
    flex-direction: column;
    margin-top: 50px;
    gap: 30px;
  }

  .button-box {
    width: 350px;
  }

  .button-text {
    font-size: 19px;
  }

  .button-icon {
    width: 50px;
    height: 50px;
  }

  .cardbox {
    margin-top: 35%;
  }

  .FlashcardArrayWrapper__CardHolder {
    height: 440px !important;
  }

  .section3-title {
    margin-top: 100px;
    margin-bottom: 50px;
} 

  .solution-detail {
    gap: 5px;
  }

  .solution-text {
    margin-bottom: 15px;
  }

  .section3-content {
    width: auto;
    gap: 20px;
  }

  .chatpage {
    min-height: auto;
    margin-top: 15%;
  }
  
  .center-div {
    width: 100vw;
    height: 85vh;
  }
  
  iframe {
    border: none;
  }


  .abt-icon {
    display: none;
  }

  .aboutpage {
    margin-top: 20%;
  }

  .aboutcard {
    width: auto;
    border-radius: 15px;
  }

  .abt-content {
    flex: 1;
    margin-right: 0px;
  }

  .abt-section1 {
    flex: none;
  }
}

.footer {
  margin-top: auto;
}

.welcome-main {
  gap: 50px;
}



@media (max-width: 1240px) {
    .right-img {
      display: none;
    }

    .left-img {
      display: none;
    }
}

@media (min-width: 992px) {
  .section3-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;
  }
}